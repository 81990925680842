var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-end"},[_c('el-button',{on:{"click":_vm.save}},[_vm._v("Save")])],1),_c('el-main',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 order-md-last"},[_c('el-input',{staticClass:"mb-3",attrs:{"clearable":"","placeholder":"Search Title/ID"},on:{"change":function($event){return _vm.updateBlogs(1)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('div',{attrs:{"align":"center"}},[(_vm.totalCount / _vm.step > 1)?_c('el-pagination',{staticClass:"m-2",attrs:{"layout":"prev, pager, next","total":_vm.totalCount,"page-size":_vm.step,"current-page":_vm.currentPage},on:{"current-change":_vm.updateBlogs}}):_vm._e()],1),_c('el-table',{attrs:{"data":_vm.blogList,"size":"small","stripe":""}},[_c('el-table-column',{attrs:{"label":"Topic","prop":"topic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row._id)),_c('br'),_c('span',{staticClass:"h6"},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.featuredBlogId.includes(row._id))?_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.removeBlogFromList(row)}}},[_vm._v("Remove")]):_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.addBlogToList(row)}}},[_vm._v("Add")])]}}])})],1)],1),_c('div',{staticClass:"col-md-6 mt-4 md-md-0"},[_c('h6',[_vm._v("最多6個Featured Blog")]),_c('el-table-draggable',{attrs:{"handle":".handle"}},[_c('el-table',{attrs:{"data":_vm.featuredBlogList,"size":"small","stripe":""}},[_c('el-table-column',{attrs:{"align":"center","label":"","width":"35"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('i',{staticClass:"fas fa-arrows-alt-v"})]}},{key:"default",fn:function(scope){return [_c('div',{staticClass:"handle"},[_c('i',{staticClass:"fas fa-bars"})])]}}])}),_c('el-table-column',{attrs:{"label":"Topic","prop":"topic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row._id)),_c('br'),_c('span',{staticClass:"h6"},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.removeBlogFromList(row)}}},[_vm._v("Remove")])]}}])})],1)],1)],1)])]),_c('el-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }