


























































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {BlogPermission} from '~/utils/permissions'
import ElTableDraggable from '~/components/sortable-el-table.vue'

@Component({
    components: {
        ElTableDraggable
    },
    metaInfo() {
        return {
            title: 'Blog Featured'
        }
    }
})
export default class BlogFeatured extends Vue {
    static permission = BlogPermission.EditBlogFeatured

    currentPage: number = 1
    totalCount: number = 0
    step: number = 10

    blogList: [] = []
    featuredBlogList: any[] = []

    keyword: string = ''

    get featuredBlogId() {
        return this.featuredBlogList.reduce((list, item) => {
            return [...list, item._id]
        }, [])
    }

    async created() {
        this.updateBlogs(1)

        createRequest('/blog/featured', 'get').send()
            .then((res) => {
                this.featuredBlogList = res.data.blog_posts.blog_posts
            }).catch((e) => {
            console.error(e)
        })
    }

    updateBlogs(page = 1) {
        this.currentPage = page

        const params: { [key: string]: string |number } = {
            status: '-1,0,1,2',
            skip: (this.currentPage - 1) * this.step,
            limit: this.step,
            count: 'true'
        }

        if (this.keyword) {
            params['keyword'] = this.keyword
        }

        createRequest('/blog/blog-posts', 'get', params).send()
            .then((res) => {
                this.blogList = res.data.blog_posts
                this.totalCount = res.data.count
            }).catch((e) => {
            console.error(e)
        })
    }

    addBlogToList(blog) {
        if (this.featuredBlogList.length < 6) {
            const found = this.featuredBlogList.find(item => item._id === blog._id)
            if (!found) {
                this.featuredBlogList.push(blog)
            } else {
                this.$message({message: 'Duplicate!', type: 'error'})
            }
        } else {
            this.$message({message: 'Maximum 6 featured blog, remove some before adding!!', type: 'error'})
        }
    }

    removeBlogFromList(blog) {
        const index = this.featuredBlogList.findIndex(item => item._id === blog._id)
        if (index > -1) {
            this.featuredBlogList.splice(index, 1)
        }
    }

    async save() {
        createRequest('/blog/featured', 'post', {}, {blogs: this.featuredBlogId}).send()
            .then((res) => {
                this.$message({message: 'Saved', type: 'success'})
            }).catch((e) => {
            console.log(e)
            this.$message({message: 'Failed', type: 'error'})
        })
    }
}

